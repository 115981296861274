.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup-inner{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #ffff;
    border-radius: 12px;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.close-popup-btn{
    position: absolute;
    top: 10px;
    right: 16px;
    background-color: #111;
    height: 40px;
    color: #fff;
    font-size: large;
    width: 42px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: large;
    font-family: unset;
    font-weight: bold;
}