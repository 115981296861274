.list-overview{
    margin-top: 70px;
    color: #fff;
    margin-bottom: 30px;
}
.list-overview-inner{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
    grid-gap: 30px;
    grid-template-rows: 1fr;
    width: 100%;
    margin: 0 auto;
}
.list-item{
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    cursor: pointer;
    margin: 5px;
    margin-bottom: 15px;
}
.item-image-remove{
    color: #fff;
    /* width: 100%; */
    text-shadow: 5px 5px 6px #000;
    cursor: pointer;
}
.item-image-img{
    width: 240px;
    height: 360px;
    cursor: pointer; 
}

