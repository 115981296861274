.signup-page{
    color: #fff;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    width: 100%;
    height: auto;
    filter: brightness(75%);
   
}
.signup-container{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center

}
.signup{
    background-image: linear-gradient(rgba(0,0,0,.8),rgba(0,0,0,.8));
    margin-top: 85px;
    height: 550px;
    width: 390px;
    position: absolute;
    top: 0;
}
input{
    /* height: 40px;
    width: 90%; */
    background: none;
    background-color: #333;
    color: #fff;
    font-size: 14px;
    padding: 10px 10px 10px 7px;
    display: block;
    width: 64.6%;
    border: none;
    border-radius: 3px;
    margin: 25px 0;
    margin-left: 13%; 
    
}
.signup-tittle{
    margin-left: 60px;
    margin-top: 35px;
    margin-bottom: 15px;
    color: #fff;
}
.signup-btn{
    min-width: 130px;
    height: 40px;
    line-height: 40px;
    padding: 0 22px;
    font-size: 14.5px;
    background-color: #e50914;
    font-weight: 600;
    font-family: helvetica;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

}
.Login-now{
    font-size: 18.5px;

}