.youtube-video{
    min-height: 100%;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
}
.youtube-container h3{
   color: #fff;
   width: 100%;
   margin-top: 70px;
   text-align: center;
}